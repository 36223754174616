import menu, { closeMenu, openMenu, openSubMenu } from "./components/menu";

import Fsbox from "fslightbox";
document.addEventListener("DOMContentLoad", () => {
    const lightbox = new Fsbox();
    lightbox.props.sources = ["first-lightbox", "example-video.mp4"];
    lightbox["first-lightbox"].props.maxImageDimensions = {
        width: "100%",
        height: "auto",
    };
    lightbox.props.onInit = () => console.log("Lightbox initialized!");
});

menu.button.open.addEventListener("click", openMenu);
menu.button.close.addEventListener("click", closeMenu);
menu.overlay.addEventListener("click", closeMenu);
menu.dropDowns.forEach((dropDown) => {
    dropDown.addEventListener("click", openSubMenu);
});

(() => {
    if (window.screen.width >= 992) {
        const floatingClose = document.querySelector(".floating__close");

        floatingClose.addEventListener("click", function () {
            this.parentElement.classList.toggle("floating--open");
            this.firstElementChild.classList.toggle("fa-times-circle");
            this.firstElementChild.classList.toggle("fa-arrow-alt-circle-up");
        });
    }
})();
